import "./App.css";
import intellibook from "../src/intellibooks.png";
import Form from "./Form";
import Footer from "./Footer";
import { useState } from "react";

function App() {
  const [openFormPopup, setOpenFormPopup] = useState(true);
  const Challenges = [
    {
      id: 1,
      title: "Long Lead Time from Concept to Production",
      description:
        "We tackle this by providing a no-code, drag-and-drop workflow tool with integrated features, which greatly speeds up development and simplifies the process with ready-made solutions.",
    },
    {
      id: 2,
      title: "Which Model is Best for Your Use Case?",
      description:
        "We allow real-time evaluation of various models, enabling you to compare results effortlessly with pre-built evaluators. This ensures you select the most effective model for your specific needs.",
    },
    {
      id: 3,
      title: "Requires complex setup and refinement.",
      description:
        "We simplify this process by offering a GenAI API marketplace, where you can instantly pick pre-configured solutions tailored to your needs, eliminating the need for extensive development.",
    },
  ];
  return (
    <div className="parent">
      {/* hedder component */}
      <div className="mx-auto flex flex-col md:flex-row items-center justify-center">
        <div className="grid grid-cols-2">
          <div className="mt-5 ml-5 md:w-600px md:pl-10">
            <img
              decoding="async"
              className="md:w-1/4 h-auto"
              src={intellibook}
              title="TPx-logo-R-333-131px"
              alt="TPx-logo-R-333-131px"
              loading="lazy"
            />
          </div>
          <div className="text-center mt-4 md:mt-5 md:w-600px">
            <span style={{ color: "gray" }} className="font-bold">
              Free Consultation
            </span>
            <p className="font-bold text-md">+1 (412) 636-3349</p>
          </div>
        </div>
      </div>

      {/* Image section */}
      <div className="text-center mt-4 pt-5 pb-10 bg-image-slide">
        <div className="grid">
          <span className="text-5xl font-extrabold text-transparent bg-gradient-to-r from-white to-orange-500 bg-clip-text pb-5 p-3 pt-10 mt-5 drop-shadow-lg transform transition duration-500 hover:scale-105 hover:text-orange-400">
            {/* <span className="text-5xl font-extrabold text-white bg-clip-text pb-5 p-3 pt-10 mt-5 drop-shadow-lg transform transition duration-500 hover:scale-105 hover:text-orange-500"> */}
            Power Your Business with AI
          </span>

          <span className="sm:w-3/5 mx-auto mb-5 p-5 text-m font-bold text-white">
            We're thrilled to showcase our advanced AI development platform,
            designed for seamless use without extensive coding knowledge.
            Discover how our cutting-edge technology offers top-tier security,
            intuitive access controls, and tailored support to meet your
            business needs.
          </span>
          <Form openFormPopup={openFormPopup} setOpenFormPopup={setOpenFormPopup} />
        </div>
      </div>
      <div className="md:p-10 p-3 mt-10 text-center font-bold md:text-4xl text-3xl">
        <span className="bg-gradient-to-r from-purple-800 via-pink-700 to-orange-700 text-transparent bg-clip-text animate-pulse">
          What challenges do customers face, and how can we address them?
        </span>
      </div>
      <div>
        <div
          style={{ maxWidth: "1200px", marginTop: "50px" }}
          className="mx-auto md:p-10 p-6"
        >
          <div className="grid md:grid-cols-3 grid-cols-1 lg:gap-10 gap-5">
            {Challenges &&
              Challenges.map((Challenge) => {
                return (
                  <div>
                    <span className="text-lg font-bold text-center block mb-5 text-blue-600">
                      Challenge {Challenge.id}
                    </span>
                    <div
                      style={{ minHeight: "350px" }}
                      className="zoom-effect shadow-2xl p-8 text-center block bg-gradient-to-br from-gray-50 to-gray-200 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl hover:bg-gradient-to-br hover:from-blue-50 hover:to-blue-100"
                    >
                      <div style={{ minHeight: "70px" }}>
                        <span className="font-extrabold block mb-4 text-xl text-indigo-700">
                          {Challenge.title}
                        </span>
                      </div>
                      <span className="mt-10 block font-bold text-gray-700">
                        {Challenge.description}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      {/* Other section/ */}
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <div className="p-10 mt-10 text-center font-bold text-4xl">
          <span className="md:text-4xl text-2xl  font-extrabold text-white md:pb-5 pb-2 p-5 md:pt-10 pt-5 mt-5 tracking-wider uppercase bg-gradient-to-r from-orange-600 via-pink-700 to-purple-800 inline-block skew-y-2 transform rotate-0 hover:rotate-3 transition-all duration-500 ease-in-out hover:text-shadow-lg hover:text-yellow-300">
            Explore Robust Features
          </span>
        </div>

        <div className="text-center text-xl p-4">
          <span className="block max-w-3xl mx-auto">
            A Comprehensive Platform for No-Code Generative AI Applications.
            Intellibooks Studio offers a complete ecosystem for businesses and
            creators.
          </span>
        </div>

        <div style={{ maxWidth: "1200px" }} className="mx-auto mt-5 p-4 pb-10">
          <ul className="space-y-10">
            <li className="block transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg">
              <div className="p-6 bg-white rounded-lg shadow-md">
                <div className="text-xl text-left font-bold underline mb-5">
                  API Marketplace
                </div>
                <span className="block text-gray-700 leading-relaxed">
                  Showcase your custom models, datasets, and Spaces through the
                  Intellibooks Studio Marketplace, ensuring privacy and control.
                  Benefit from integrated features like role-based access
                  control, pull request discussions, pricing, feedback, and
                  versioning. For example, you can use this marketplace to share
                  a built-in generative AI API tailored for business
                  applications.
                </span>
              </div>
            </li>
            <li className="block transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg">
              <div className="p-6 bg-white rounded-lg shadow-md">
                <div className="text-xl text-left font-bold underline mb-5">
                  Model Evaluation
                </div>
                <span className="block text-gray-700 leading-relaxed">
                  Assessing models from Amazon Bedrock, OpenAI, Azure OpenAI,
                  and Google Vertex AI is effortless with our no-code platform.
                  Developers can easily evaluate model performance, accuracy,
                  and suitability using our intuitive tools and interfaces,
                  ensuring they select the ideal model for their projects with
                  confidence.
                </span>
              </div>
            </li>
            <li className="block transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg">
              <div className="p-6 bg-white rounded-lg shadow-md">
                <div className="text-xl text-left font-bold underline mb-5">
                  Logging and Monitoring
                </div>
                <span className="block text-gray-700 leading-relaxed">
                  Our no-code platform streamlines the monitoring, logging, and
                  debugging of generative AI models such as Amazon Bedrock,
                  OpenAI, Azure OpenAI, and Google Vertex AI. Developers enjoy
                  user-friendly tools and an intuitive interface, enhancing
                  their generative AI development experience with ease and
                  efficiency. Elevate your AI projects with our comprehensive
                  platform.
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* Footer */}
      <Footer></Footer>
    </div>
  );
}

export default App;
